.header {
  @apply flex;
  @apply items-center;
  padding-top: 0.75rem;
  padding-bottom: 1.25rem;
  @apply mb-6;
  @apply col-span-12;
}

.header-logo {
  @apply leading-none;
}

.header-logo-text {
  position: relative;
  top: -3px;
}

.btn--header-dropdown {
  padding-right: 10px;
}

@screen lg {
  .header-user {
    @apply flex;
    @apply items-center;
    @apply justify-end;
  }

  .header-logo-text {
    @apply block;
  }
}

@media (max-width: theme('screens.md+.max')) {
  .header-user .header-logo {
    flex: 1;
  }

  .header-logo {
    display: flex;
    position: relative;
    top: 2px;
  }
}
