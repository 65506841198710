@font-face {
  font-family: "icons";
  src: url("/fonts/icons.c329a135.woff2") format("woff2"),
url("/fonts/icons.c329a135.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon-arrow-down:before {
	content: "\f101";
}
.icon-arrow-right:before {
	content: "\f102";
}
.icon-arrow-up:before {
	content: "\f103";
}
.icon-calculator:before {
	content: "\f104";
}
.icon-calendar:before {
	content: "\f105";
}
.icon-chat-help:before {
	content: "\f106";
}
.icon-chat-text:before {
	content: "\f107";
}
.icon-check-tick-circle:before {
	content: "\f108";
}
.icon-check-tick:before {
	content: "\f109";
}
.icon-cheeseburger:before {
	content: "\f10a";
}
.icon-chevron-down-small:before {
	content: "\f10b";
}
.icon-chevron-left-large:before {
	content: "\f10c";
}
.icon-chevron-left-small:before {
	content: "\f10d";
}
.icon-chevron-right-large:before {
	content: "\f10e";
}
.icon-chevron-right-small:before {
	content: "\f10f";
}
.icon-chevron-up-small:before {
	content: "\f110";
}
.icon-clock-pending:before {
	content: "\f111";
}
.icon-close:before {
	content: "\f112";
}
.icon-communication-1:before {
	content: "\f113";
}
.icon-communication-32:before {
	content: "\f114";
}
.icon-date:before {
	content: "\f115";
}
.icon-desktop:before {
	content: "\f116";
}
.icon-doc-check:before {
	content: "\f117";
}
.icon-doc-long:before {
	content: "\f118";
}
.icon-dollar-coin:before {
	content: "\f119";
}
.icon-edit:before {
	content: "\f11a";
}
.icon-ellipsis-horizontal:before {
	content: "\f11b";
}
.icon-external-link:before {
	content: "\f11c";
}
.icon-eye-hide:before {
	content: "\f11d";
}
.icon-eye-show:before {
	content: "\f11e";
}
.icon-file:before {
	content: "\f11f";
}
.icon-home:before {
	content: "\f120";
}
.icon-information-circle:before {
	content: "\f121";
}
.icon-interface-cancel:before {
	content: "\f122";
}
.icon-interface-download:before {
	content: "\f123";
}
.icon-loading:before {
	content: "\f124";
}
.icon-location-pin:before {
	content: "\f125";
}
.icon-mail:before {
	content: "\f126";
}
.icon-mailbox:before {
	content: "\f127";
}
.icon-mobile:before {
	content: "\f128";
}
.icon-money-bag:before {
	content: "\f129";
}
.icon-phone:before {
	content: "\f12a";
}
.icon-retry:before {
	content: "\f12b";
}
.icon-search:before {
	content: "\f12c";
}
.icon-shield-check:before {
	content: "\f12d";
}
.icon-social-facebook:before {
	content: "\f12e";
}
.icon-sort-ascending:before {
	content: "\f12f";
}
.icon-sort-descending:before {
	content: "\f130";
}
.icon-speedometer-fast:before {
	content: "\f131";
}
.icon-support-call:before {
	content: "\f132";
}
.icon-sync:before {
	content: "\f133";
}
.icon-trash:before {
	content: "\f134";
}
.icon-upload:before {
	content: "\f135";
}
.icon-user-circle:before {
	content: "\f136";
}
.icon-wallet:before {
	content: "\f137";
}
.icon-warning-triangle-filled:before {
	content: "\f138";
}
.icon-warning-triangle:before {
	content: "\f139";
}
