@layer components {
  .dlist {
    @apply mb-2;
  }

  .dlist:last-child {
    @apply mb-0;
  }

  .dlist-headline {
    @apply font-bold;
    @apply mb-4;
  }

  .dlist-item {
    @apply mb-0;
  }

  .dlist-item:last-child {
    @apply mb-0;
  }

  /* these screen cut off and last child count needs to match with DetailsCard */
  .dlist-item:nth-last-child(-n+2) {
    @apply mb-0;
  }

  @media (min-width: theme('screens.sm')) and (max-width: theme('screens.lg')) {
    .dlist-item:nth-last-child(-n+3) {
      @apply mb-0;
    }
  }

  @media (min-width: theme('screens.lg')) and (max-width: theme('screens.xl')) {
    .dlist-item:nth-last-child(-n+2) {
      @apply mb-0;
    }
  }

  @media (min-width: theme('screens.xl')) {
    .dlist-item:nth-last-child(-n+3) {
      @apply mb-0;
    }
  }

  .dlist-break {
    border-bottom: 1px solid;
    @apply border-gray-25;
    width: 100%;
    @apply my-4;
  }

  .dlist--stacked .dlist-label {
    @apply text-body;
    @apply mb-1;
  }

  .dlist--inline .dlist-item {
    display: flex;
    justify-content: space-between;
  }

  .dlist--inline .dlist-item .dlist-label,
  .dlist--inline .dlist-item .dlist-value {
    flex: 1;
  }

  .dlist--inline .dlist-item .dlist-value {
    text-align: right;
  }
}
