@layer components {
  .radio-item {
    display: inline-block;
    position: relative;
    padding: 0 4px;
    margin: 10px 0 0;
  }

  .radio-item input {
    display: none;
  }

  .radio-item label:before {
    content: " ";
    display: inline-block;
    position: relative;
    top: 6px;
    margin: 0 10px 0 0;
    width: 24px;
    height: 24px;
    border-radius: 14px;
    border: 1px solid;
    @apply border-action-100;
    background-color: transparent;
  }

  .radio-item input:checked + label:before {
    @apply bg-input-10;
    @apply border-action-125;
  }

  .radio-item input:checked + label:after {
    border-radius: 11px;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 13px;
    left: 11px;
    content: " ";
    display: block;
    @apply bg-action-125;
  }
}
