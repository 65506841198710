@layer components {
  .mini-spinner {
    animation: mini-spinner 0.7s infinite linear;
  }

  @keyframes mini-spinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
