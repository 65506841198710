@layer components {
  .form-group {
    position: relative;
    @apply mb-6;
  }

  .form-group:last-child {
    @apply mb-0;
  }

  .form-label {
    position: absolute;
    width: fit-content;
    height: fit-content;
    top: 14px;
    z-index: 2;
    @apply pl-4;
    display: flex;
    align-items: center;
    transform-origin: top left;
    transform: translate(0, 0) scale(1);
    transition: transform 0.1s ease-in-out;
    cursor: text;
    user-select: none;
  }

  .form-group--active .form-label {
    transform: translate(4px, -4px) scale(0.7);
    letter-spacing: 0.039375rem;
    @apply text-body;
    pointer-events: none;
    padding-bottom: 2px;
    top: 10px;
  }

  .form-input {
    @apply bg-formBg;
    border: 1px solid;
    @apply border-formBorder;
    border-radius: 4px;
    @apply px-4;
    @apply pt-4;
    height: 3.125rem;
    width: 100%;
    box-shadow: none;
    -webkit-appearance: none;
    transition: border-color 0.15s ease-in-out;
  }

  .form-group-textarea {
    min-height: 6.25rem;
    max-height: 18.75rem;
  }

  .form-textarea {
    min-height: 6.25rem;
    max-height: 18.75rem;
    @apply pt-6;
  }

  .form-input:focus {
    outline: 0;
    @apply border-formBorderFocus;
  }

  /* stylelint-disable */
  @keyframes onAutoFillStart {
    from {/**/}
    to {/**/}
  }

  @keyframes onAutoFillCancel {
    from {/**/}
    to {/**/}
  }
  /* stylelint-enable */

  .form-input:-webkit-autofill {
    /* Expose a hook for JavaScript when auto fill is shown. */
    /* JavaScript can capture 'animationstart' events */
    animation-name: onAutoFillStart;
    transition: background-color 50000s ease-in-out 0s;
  }

  .form-input:not(:-webkit-autofill) {
    /* Expose a hook for JS onAutoFillCancel */
    /* JavaScript can capture 'animationstart' events */
    animation-name: onAutoFillCancel;
  }

  /* Error States */
  .form-group--error .form-input {
    /* input error state */
    border: 1px solid #f3d0d9;
    background-color: #f6dfe5;
  }

  .form-group--error .form-error-text {
    /* message error state */
    color: #d04367;
    font-size: 0.6875rem;
    position: absolute;
    top: 100%;
    z-index: 1;
    margin-top: 1px;
  }

  .form-button-error-text {
    color: #d04367;
    font-size: 0.6875rem;
    @apply leading-normal;
  }

  .form-btn-group {
    position: relative;
    @apply mt-10;
  }

  .form-error {
    color: #d04367;
    font-size: 0.6875rem;
  }

  .form-btn-group .form-error {
    position: absolute;
    bottom: 100%;
    @apply mb-2;
  }

  .submitting {
    position: relative;
  }

  .submitting::before {
    content: "";
    background: #fff;
    opacity: 0.01;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
  }
}
