.task {
  border-bottom: 1px solid;
  @apply border-gray-25;
}

@media (max-width: theme('screens.md+.max')) {
  .tasks-section:last-child .tasks :last-child .task {
    border: 0;
  }
}

/* Task Collapsable */

.task--collapsable .accordion__title {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
