@layer components {
  .article-body h2 {
    @apply text-2xl;
    @apply py-4;
  }
  .article-body h3 {
    @apply text-xl;
    @apply py-4;
  }
  .article-body p {
    @apply text-base;
    @apply pb-4;
  }
  .article-body ul {
    @apply pl-8;
    @apply pb-4;
  }
  .article-body ul li::before {
    content: "\2022";
    width: 1em;
    margin-left: -1em;
    @apply text-brand-100;
    @apply bold;
    @apply inline-block;
  }
  .article-body ol {
    @apply pl-8;
    @apply pb-4;
    counter-reset: li;
  }
  .article-body ol li {
    counter-increment: li;
  }
  .article-body ol li::before {
    content: counter(li);
    width: 1em;
    margin-left: -1.5em;
    margin-right: 0.5em;
    direction: rtl;
    @apply text-brand-100;
    @apply bold;
    @apply inline-block;
  }
}
