@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova Regular'), local('ProximaNova-Regular'),
    url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/proxima_nova_regular.woff2?version=.Ip7JDqhugOYTA76RSljx3J.zY_EAETC')
      format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova SemiBold'), local('ProximaNova-SemiBold'),
    url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/proxima_nova_semibold.woff2?version=dHAD64P7ayBbEUSiBD.KATFw02LP.ZSS')
      format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova Bold'), local('ProximaNova-Bold'),
    url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/proxima_nova_bold.woff2?version=gjFvEHOoOfvUdsz6PjVpqx6Baw_j5.Uv')
      format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova Black'), local('ProximaNova-Black'),
    url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/proxima-nova-black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova Condensed'), local('ProximaNova-Condensed'),
    url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/proxima_nova_condensed_regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-stretch: condensed;
}

@font-face {
  font-family: 'TT Norms';
  src: local('TT Norms Regular'), local('TT Norms-Regular'),
    url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/tt_norms_regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'TT Norms';
  src: local('TT Norms Medium'), local('TT Norms-Medium'),
    url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/tt_norms_medium.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'TT Norms';
  src: local('TT Norms Bold'), local('TT Norms-Bold'),
    url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/tt_norms_bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
    url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/montserrat_regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
    url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/montserrat_medium.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
    url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/montserrat_bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: local('Montserrat Black'), local('Montserrat-Black'),
    url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/montserrat_black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}

/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/lato-italic-ext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: local('Lato Italic'), local('Lato-Italic'),
    url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/lato-italic.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/lato-bolditalic-ext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  src: local('Lato BoldItalic'), local('Lato-BoldItalic'),
    url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/lato-bolditalic.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/lato-blackitalic-ext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: local('Lato BlackItalic'), local('Lato-BlackItalic'),
    url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/lato-blackitalic.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/lato-regular-ext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local('Lato Regular'), local('Lato-Regular'),
    url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/lato-regular.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/lato-bold-ext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: local('Lato Bold'), local('Lato-Bold'),
    url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/lato-bold.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/lato-black-ext.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: local('Lato Black'), local('Lato-Black'),
    url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/lato-black.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191,
    U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: local('Avenir Next LT Pro'), local('Avenir-Next-LT-Pro'),
    url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/avenir-next-lt-pro-regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: local('Avenir Next LT Pro Bold'), local('Avenir-Next-LT-Pro-Bold'),
    url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/avenir-next-lt-pro-bold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Compass Sans';
  src: url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/compass_sans_regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Compass Sans';
  src: url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/compass_sans_regular_italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Compass Sans';
  src: url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/compass_sans_medium.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Compass Sans';
  src: url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/compass_sans_medium_italic.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Compass Sans';
  src: url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/compass_sans_bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Compass Sans';
  src: url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/compass_sans_bold_italic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Domaine Display';
  src: url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/domaine_display_regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/poppins_regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/poppins_medium.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/poppins_bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'EB-Garamond';
  src: url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/ebgaramond-regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'EB-Garamond';
  src: url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/ebgaramond-medium.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'EB-Garamond';
  src: url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/ebgaramond-bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Switzer';
  src: url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/switzer-regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Switzer';
  src: url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/switzer-medium.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Switzer';
  src: url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/switzer-bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/Roboto-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/Roboto-Medium.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: url('https://d2fcchof1bpxmv.cloudfront.net/cms/fonts/Roboto-Bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

/* downloading a Adobe typekit font and hosting from another location is a violation of the TOU and therefore the License */
