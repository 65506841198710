@layer base {
  @keyframes pulse {
    from {
      transform: scaleX(1);
    }

    50% {
      transform: scale3d(1.2, 1.2, 1.2);
    }

    to {
      transform: scaleX(1);
    }
  }

  .animate__pulse {
    animation-name: pulse;
    animation-timing-function: ease-in-out;
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }

  .animate__shimmer {
    animation-name: shimmer;
    animation-duration: 2s;
    animation-timing-function: infinite;
  }

  @keyframes slideInLeft {
    from {
      -webkit-transform: translate3d(-100%, 0, 0);
      transform: translate3d(-100%, 0, 0);
      visibility: visible;
    }

    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  .animate__slideInLeft {
    animation-name: slideInLeft;
  }

  @keyframes slideInUp {
    from {
      -webkit-transform: translate3d(0, 100%, 0);
      transform: translate3d(0, 100%, 0);
      visibility: visible;
    }

    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }
  }

  .animate__slideInUp {
    animation-name: slideInUp;
  }

  @keyframes slideOutRight {
    from {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    to {
      visibility: hidden;
      -webkit-transform: translate3d(100%, 0, 0);
      transform: translate3d(100%, 0, 0);
    }
  }

  .animate__slideOutRight {
    animation-name: slideOutRight;
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(359deg);
    }
  }

  .animate__rotation {
    animation-name: rotation;
  }
}
