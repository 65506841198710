.embed .app-container > .app-wrap {
  @apply pt-4;
}

@screen md {
  .embed .app-container > .app-wrap {
    @apply pt-8;
  }
}

@screen lg {
  .embed .app-container > .app-wrap {
    @apply pt-10;
  }
}
