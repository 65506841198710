@layer components {
  .spinner {
    position: relative;
    @apply flex;
    @apply items-center;
    @apply justify-center;
  }

  .spinner-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, -50%);
    width: 120px;
    @apply bg-center;
    @apply bg-no-repeat;
  }

  .spinner-spinning {
    @apply h-40;
    @apply w-40;
    @apply rounded-full;
    @apply border-l-4;
    @apply border-dotted;
    @apply border-gray-25;
    @apply animate-spin;
    @apply ease-in-out;
  }

  /* Center Spinner */
  .full .spinner {
    margin-top: -5rem;
  }

  @screen md {
    .spinner {
      background-size: 7.4rem 7.4rem;
    }

    .spinner-spinning {
      @apply h-48;
      @apply w-48;
    }

    /* Center Spinner */
    .full .spinner {
      margin-top: -6rem;
    }
  }
}
