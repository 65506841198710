@layer base {
  .icon {
    pointer-events: none;
    /* use !important to prevent issues with browser extensions that change fonts */
    /* stylelint-disable-next-line font-family-no-missing-generic-family-keyword */
    font-family: icons !important;
    speak: never;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  [class^="icon-"],
  [class*=" icon-"] {
    @apply icon;
  }
}
