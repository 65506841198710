@layer base {
  hr {
    @apply border-gray-25;
  }
  
  .full {
    @apply w-full;
    @apply min-h-screen;
    @apply flex;
    @apply items-center;
    @apply justify-center;
  }

  /* Text meant only for screen readers. */
  .screen-reader-text {
    border: 0;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
    word-wrap: normal !important;
  }
}
