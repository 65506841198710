@layer components {
  .photo {
    @apply overflow-hidden;
    @apply items-center;
    @apply justify-center;
    @apply bg-gray-10;
  }

  .photo-rounded {
    @apply inline-flex;
    @apply rounded-full;
  }

  .photo-40 {
    @apply flex;
    width: 2.5rem;
    height: 2.5rem;
  }

  .photo-72 {
    @apply flex;
    width: 4.5rem;
    height: 4.5rem;
  }

  .photo--placeholder {
    background: none;
    justify-content: start;
    border-width: 1px;
    @apply border-gray-25;
    @apply p-1;
  }

  .photo--placeholder.photo-40,
  .photo--placeholder.photo-72 {
    @apply p-2;
  }

  .photo--placeholder.photo-40 img {
    max-height: 40px;
    width: auto;
  }

  .photo--placeholder.photo-72 img {
    max-height: 72px;
    width: auto;
  }
}
