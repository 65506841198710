@layer components {
  .email {
    display: inline-block;
  }

  .email .email-span {
    display: inline-flex;
    align-items: flex-start;
    line-height: 1.6;
    margin-top: 2px;
  }

  .email .email-label {
    text-decoration: none;
    margin-top: 2px;
  }

  .email:hover .email-label {
    text-decoration: underline;
  }
}
