html,
body {
  @apply h-full;
}

.app-container {
  @apply flex;
  @apply flex-col;
  @apply min-h-screen;
  @apply justify-between;
  overflow: hidden;
}

.app-wrap {
  width: 100%;
  @apply mx-auto;
  @apply px-4;
}

/* Mobile first, 375px to 767px */
.app-page {
  @apply flex;
  @apply flex-col;
  @apply min-h-screen;
  @apply overflow-hidden;
  @apply px-6;
  @apply mx-auto;
  max-width: 90rem;
  @apply justify-between;
}

.app-page-with-nav {
  @apply w-full;
  @apply flex;
  @apply flex-col;
  @apply flex-grow;
}

.app-page-contents {
  @apply grid;
  @apply grid-cols-4;
  @apply gap-x-12;
}

.main-body,
.main-body-full {
  @apply grid;
  @apply grid-cols-4;
  @apply col-span-4;
  @apply gap-x-12;
  @apply pt-8;
}

.main-body-contents,
.main-body-full-contents {
  @apply col-span-4;
}

.right-rail {
  @apply grid;
  @apply grid-cols-4;
  @apply col-span-4;
}

.right-rail-contents {
  @apply col-span-4;
  @apply lg:mt-8;
  @apply relative;
}

.right-rail-contents-wrapper {
  @apply relative;
  @apply grid;
  @apply grid-cols-4;
  @apply col-span-4;
}

@screen md {
  /* Tablet, 768px to 1023px */
  .app-page-contents {
    @apply grid-cols-8;
  }

  .main-body,
  .main-body-full {
    @apply grid-cols-8;
    @apply col-span-8;
  }

  .main-body-contents,
  .main-body-full-contents {
    @apply col-span-8;
  }

  .right-rail,
  .right-rail-contents-wrapper {
    @apply grid;
    @apply grid-cols-8;
    @apply col-span-8;
  }

  .right-rail-contents {
    @apply col-span-8;
  }
}

@screen lg {
  /* Small desktop, 1024px to 1439px */
  .app-page-contents {
    @apply grid-cols-12;
  }

  .main-body-full {
    @apply grid-cols-12;
    @apply col-span-12;
  }

  .main-body-full-contents {
    @apply col-span-10;
    @apply col-start-2;
  }

  .right-rail,
  .right-rail-contents-wrapper {
    @apply grid;
    @apply grid-cols-4;
    @apply col-span-4;
  }

  .right-rail-contents {
    @apply col-span-4;
  }

  /* overflow needs to be visible for card shadow in loan center */
  .main-content {
    min-width: 0;
    @apply flex-1;
  }
}

@screen xl {
  /* Large desktop, 1440px and up */
  .right-rail-contents {
    @apply col-span-3;
  }

  .main-body-full-contents {
    @apply col-span-8;
    @apply col-start-3;
  }
}

/* Main content tablet and mobile styles */
@media (max-width: theme('screens.md+.max')) {
  .main-content {
    max-width: var(--main-content-width);
    margin-left: auto;
    margin-right: auto;
  }
}