@layer base {
  body {
    @apply text-body;
    @apply font-sans;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.5;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    @apply font-bold;
  }

  p {
    margin: 0;
  }

  address {
    font-style: normal;
  }

  a {
    @apply text-body;
    @apply underline;
  }

  a:hover {
    @apply no-underline;
  }

  .a-reset {
    @apply no-underline;
    @apply text-body;
    @apply transition-none;
  }

  .text-headline {
    @apply font-bold;
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .text-subheadline {
    @apply font-bold;
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .body-large {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .body-medium {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .body-small {
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .body-disclaimer {
    font-size: 0.75rem;
    line-height: 1rem;
    font-stretch: condensed;
  }

  .body-label {
    font-size: 0.75rem;
    line-height: 0.75rem;
    letter-spacing: 0.75px;
  }

  .bold {
    @apply font-bold;
  }

  .text-normal {
    font-weight: normal;
  }

  .header-large {
    @apply font-bold;
    font-size: 3rem;
    line-height: 3.5rem;
  }

  .header-medium {
    @apply font-bold;
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .header-small {
    @apply font-bold;
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .list-disc-red {
    list-style: none;
  }

  .list-disc-red li::before {
    @apply text-primary;
    @apply font-bold;
    content: "\2022";
    display: inline-block;
    width: 1em;
    margin-left: -1em;
  }

  .text-md {
    font-size: 1rem;
  }

  .text-32 {
    font-size: 32px;
  }

  .text-20 {
    font-size: 20px;
  }

  @responsive {
    .text-marketing-md {
      @apply text-5xl;
      @apply font-black;
      line-height: 56px;
    }

    .text-marketing-xs {
      @apply font-black;
      font-size: 32px;
      line-height: 40px;
    }
  
    .text-marketing-2xs {
      @apply font-black;
      font-size: 24px;
      line-height: 32px;
    }
  }

  @screen md {
    .md\:text-32 {
      font-size: 32px;
    }

    .md\:text-20 {
      font-size: 20px;
    }
  }
}
