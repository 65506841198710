.navigation {
  max-width: 224px;
}

.navigation > li,
.subnavigation > li {
  @apply my-2;
}

.navigation > li:first-child,
.subnavigation > li:first-child {
  @apply mt-0;
}

.navigation > li:last-child,
.subnavigation > li:last-child {
  @apply mb-0;
}

.subnavigation {
  margin-top: 2px;
  margin-bottom: 2px;
  @apply ml-5;
  @apply pl-7;
  @apply border-l-2;
  @apply border-action-10;
}

.top-menu-section {
  padding-top: 78px;
}

@screen lg {
  .top-menu-section {
    padding-top: 56px;
  }
}