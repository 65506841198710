@layer components {
  .hamburger {
    width: 40px;
    height: 40px;
    position: relative;
    @apply rounded-full;
    left: -10px;
  }

  .hamburger:hover {
    @apply bg-icon-hover;
  }

  .hamburger-inner,
  .hamburger-inner::before,
  .hamburger-inner::after {
    display: block;
    position: absolute;
    @apply bg-body;
    width: 20px;
    height: 2px;
    transform-origin: center center;
    transform: rotate(0deg);
    transition: all 0.3s ease;
  }

  .hamburger-inner {
    top: 50%;
    margin-top: -1px;
    left: 10px;
  }

  .hamburger-inner::before,
  .hamburger-inner::after {
    content: "";
  }

  .hamburger-inner::before {
    top: 6px;
  }

  .hamburger-inner::after {
    bottom: 6px;
  }
}
