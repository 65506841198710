@layer components {
  /* Button Base */
  .btn {
    display: inline-block;
    @apply text-base;
    @apply a-reset;
    @apply font-bold;
    transition-property: color, border-color, background-color, transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }

  /* Button Disabled */
  [disabled],
  button:disabled,
  .btn:disabled,
  .btn--disabled {
    pointer-events: none;
  }

  /* Button Loading */
  .btn--loading {
    pointer-events: none;
  }

  .btn-spinner {
    position: relative;
  }

  /* Button Spinner */
  .btn-spinner .mini-spinner {
    margin-top: -8px;
    margin-left: -8px;
    position: absolute;
    top: 50%;
    left: 50%;
  }

  /* Button Types */

  /* Button Primary */
  .btn--primary {
    @apply bg-primaryBtn-bg;
    @apply text-primaryBtn-text;
    @apply border;
    @apply border-primaryBtn-border;
    @apply rounded-primaryBtn;
  }

  .btn--primary:hover,
  .btn--primary.hover,
  .btn--primary.active {
    @apply bg-primaryBtn-hoverBg;
    @apply text-primaryBtn-hoverText;
  }

  .btn--primary.active {
    transform: scale(0.9);
  }

  .btn--primary.btn--disabled {
    @apply bg-primaryBtn-disabledBg;
    @apply text-primaryBtn-disabledText;
  }

  .btn--primary .mini-spinner {
    border-bottom-color: #fff;
    border-right-color: #fff;
  }

  /* Button Secondary */
  .btn--secondary {
    @apply bg-secondaryBtn-bg;
    @apply text-secondaryBtn-text;
    @apply border;
    @apply border-secondaryBtn-border;
    @apply rounded-secondaryBtn;
  }

  .btn--secondary:hover,
  .btn--secondary.hover,
  .btn--secondary.active {
    @apply bg-secondaryBtn-hoverBg;
    @apply text-secondaryBtn-hoverText;
    @apply border-secondaryBtn-hoverBorder;
  }

  .btn--secondary.active {
    transform: scale(0.9);
  }

  .btn--secondary.btn--disabled {
    @apply bg-secondaryBtn-disabledBg;
    @apply text-secondaryBtn-disabledText;
    @apply border-secondaryBtn-disabledBorder;
  }

  /* Button Primary & Secondary */
  .btn--primary,
  .btn--secondary {
    line-height: 1;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    @apply px-5;
    @apply py-3.5;
    min-width: 6.125rem; /* 98px */
    min-height: 2.5rem; /* 40px minus padding */
  }

  /* Button Tertiary */
  .btn--tertiary {
    @apply text-tertiaryBtn-text;
  }

  .btn--tertiary:hover,
  .btn--tertiary.hover,
  .btn--tertiary.active {
    @apply text-tertiaryBtn-hoverText;
  }

  .btn--tertiary.active {
    transform: scale(0.9);
  }

  .btn--tertiary.btn--disabled {
    @apply text-tertiaryBtn-disabledText;
  }

  /* Button Quaternary */
  .btn--quaternary {
    @apply text-body-100;
  }

  .btn--quaternary:hover,
  .btn--quaternary.hover,
  .btn--quaternary.active {
    @apply text-body-125;
  }

  .btn--quaternary.active {
    transform: scale(0.9);
  }

  .btn--quaternary.btn--disabled {
    @apply text-inactive-75;
  }

  /* Button Tertiary & Quaternary */
  .btn--tertiary,
  .btn--quaternary,
  .btn--plain {
    text-decoration: none;
  }

  .btn--tertiary:hover,
  .btn--tertiary.hover,
  .btn--tertiary.active,
  .btn--quaternary:hover,
  .btn--quaternary.hover,
  .btn--quaternary.active,
  .btn--plain:hover,
  .btn--plain.hover,
  .btn--plain.active {
    text-decoration: underline;
  }

  .btn--tertiary.btn--has-icon:hover,
  .btn--tertiary.btn--has-icon.hover,
  .btn--tertiary.btn--has-icon.active,
  .btn--quaternary.btn--has-icon:hover,
  .btn--quaternary.btn--has-icon.hover,
  .btn--quaternary.btn--has-icon.active,
  .btn--plain.btn--has-icon:hover,
  .btn--plain.btn--has-icon.hover,
  .btn--plain.btn--has-icon.active {
    text-decoration: none;
  }

  .btn--tertiary.btn--has-icon:hover .btn-text,
  .btn--tertiary.btn--has-icon.hover .btn-text,
  .btn--tertiary.btn--has-icon.active .btn-text,
  .btn--quaternary.btn--has-icon:hover .btn-text,
  .btn--quaternary.btn--has-icon.hover .btn-text,
  .btn--quaternary.btn--has-icon.active .btn-text,
  .btn--plain.btn--has-icon:hover .btn-text,
  .btn--plain.btn--has-icon.hover .btn-text,
  .btn--plain.btn--has-icon.active .btn-text {
    text-decoration: underline;
  }

  /* Button Inline */
  .btn--inline {
    font-weight: 400;
    text-decoration: underline;
  }

  .btn--inline.btn--has-icon {
    text-decoration: none;
  }

  .btn--inline.btn--has-icon .btn-text {
    text-decoration: underline;
  }

  .btn--inline:hover,
  .btn--inline.hover,
  .btn--inline.active {
    text-decoration: none;
  }

  .btn--inline.btn--has-icon:hover .btn-text,
  .btn--inline.btn--has-icon.hover .btn-text,
  .btn--inline.btn--has-icon.active .btn-text {
    text-decoration: none;
  }

  /* Button Plain */
  .btn--plain {
    font-weight: 400;
  }

  /* Button Icon Only */
  .btn--icon {
    line-height: 1;
    transition-duration: 0.25s;
  }

  .btn--icon:hover,
  .btn--icon.hover {
    @apply text-action-125;
  }

  .btn--icon-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    @apply text-body;
    @apply rounded-full;
  }

  .btn--icon-bg:hover,
  .btn--icon-bg.hover {
    @apply bg-action-10;
  }

  .btn--icon-rotate:hover,
  .btn--icon-rotate.hover {
    transform: rotate(90deg);
    transition-property: transform;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
  }

  /* Button Navigation */

  /* Button Nav */
  .btn--nav .btn-wrap {
    align-items: flex-start;
  }

  .btn--nav .btn-icon {
    font-size: 1.25rem;
    @apply mr-4;
  }

  /* Button Subnav */
  .btn--nav,
  .btn--subnav {
    display: flex;
    align-items: center;
    @apply text-body;
    font-size: 1rem; /* 16px */
    line-height: 1;
    font-weight: normal;
    user-select: none;
    @apply rounded-xl;
    @apply px-3;
    @apply py-2;
  }

  .btn--subnav {
    font-size: 1rem; /* 20px */
    white-space: nowrap;
  }

  .btn--nav .btn-text {
    white-space: normal;
  }

  .btn--nav:hover,
  .btn--nav.hover,
  .btn--subnav:hover,
  .btn--subnav.hover {
    @apply text-body-125;
  }

  .btn--nav.btn--has-icon:hover,
  .btn--nav.btn--has-icon.hover,
  .btn--nav.btn--has-badge:hover,
  .btn--nav.btn--has-badge.hover,
  .btn--subnav.btn--has-icon:hover,
  .btn--subnav.btn--has-icon.hover,
  .btn--subnav.btn--has-badge:hover,
  .btn--subnav.btn--has-badge.hover {
    text-decoration: none;
  }

  .btn--nav:not(.btn--has-icon):not(.btn--has-badge):hover,
  .btn--nav:not(.btn--has-icon):not(.btn--has-badge).hover,
  .btn--nav.btn--has-icon:hover .btn-text,
  .btn--nav.btn--has-icon.hover .btn-text,
  .btn--nav.btn--has-badge:hover .btn-text,
  .btn--nav.btn--has-badge.hover .btn-text,
  .btn--subnav:not(.btn--has-icon):not(.btn--has-badge):hover,
  .btn--subnav:not(.btn--has-icon):not(.btn--has-badge).hover,
  .btn--subnav.btn--has-icon:hover .btn-text,
  .btn--subnav.btn--has-icon.hover .btn-text,
  .btn--subnav.btn--has-badge:hover .btn-text,
  .btn--subnav.btn--has-badge.hover .btn-text {
    text-decoration: underline;
  }

  /* selected */
  .nav-current,
  /* I'm a parent and my child is selected */
  .nav-current-ancestor {
    @apply bg-action-10;
  }

  /* selected btn */
  .nav-current .btn,
  .nav-current:hover .btn,
  .nav-current.hover .btn,
  /* I'm a parent and my child is selected */
  .nav-current-ancestor .btn,
  .nav-current-ancestor:hover .btn,
  .nav-current-ancestor.hover .btn {
    @apply font-bold;
    @apply text-action-125;
    text-decoration: none;
  }

  .nav-current .btn:not(.btn--has-icon):not(.btn--has-badge):hover,
  .nav-current .btn:not(.btn--has-icon):not(.btn--has-badge).hover,
  .nav-current .btn.btn--has-icon:hover .btn-text,
  .nav-current .btn.btn--has-icon.hover .btn-text,
  .nav-current .btn.btn--has-badge:hover .btn-text,
  .nav-current .btn.btn--has-badge.hover .btn-text {
    text-decoration: none;
  }

  /* Button Sizes */
  .btn--small {
    font-size: 0.875rem;
  }

  .btn--primary.btn--small,
  .btn--secondary.btn--small {
    min-width: 5.125rem; /* 82px */
    min-height: 2.25rem; /* 36px minus padding */
    @apply py-2;
  }

  .btn--large {
    font-size: 1.25rem;
  }

  .btn--primary.btn--large,
  .btn--secondary.btn--large {
    min-width: 7.5rem; /* 120px */
    min-height: 3.25rem; /* 52px */
    @apply py-4;
  }

  .btn--medium {
    font-size: 1rem;
  }
}
