@layer components {
  .tel-link {
    text-decoration: underline;
    transition-property: none;
    @apply text-body;
  }

  .tel-link:hover {
    text-decoration: none;
  }

  .tel-link.no-underline {
    text-decoration: none;
  }

  .tel-link.no-underline:hover .tel-label {
    text-decoration: underline;
  }
}
