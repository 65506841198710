@layer components {
  .circle {
    @apply rounded-full;
    @apply p-1;
    @apply text-white;
    @apply inline-flex;
    @apply items-center;
    @apply justify-center;
    @apply font-bold;
    width: 1.5rem;
    height: 1.5rem;
  }
}
