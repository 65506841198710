.error-view {
  text-align: center;
}

.error-view-icon {
  display: flex;
  justify-content: center;
}

.error-view-header {
  font-size: 32px;
  line-height: 40px;
}

@screen lg {
  .error-view-header {
    font-size: 48px;
    line-height: 56px;
  }
}

@media (max-width: theme('screens.md+.max')) {
  .error-view-header {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }

  .error-view-btn {
    display: flex;
  }
}
